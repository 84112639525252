import React from 'react';
import { GET_FAVORITE_QUERY } from '../../api';
import { useQuery } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from './../../store/userSlice';

// IMPORTS COMPOSANTS
import './Favoris.css';
import ProgrammesCompo from '../../components/actions/ProgrammesCompo';
import SeancesCompo from '../../components/actions/SeancesCompo';
import ConseilsCompo from '../../components/actions/ConseilsCompo';

// Imports FontAwesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import { faChevronLeft, faUser } from '@fortawesome/pro-solid-svg-icons';

library.add(faChevronLeft, faUser);

const Favoris = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.token);
  const { loading, error: errorToken, data } = useQuery(GET_FAVORITE_QUERY, {
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
    fetchPolicy: 'no-cache',
  });


  const dispatch = useDispatch();

  // Vérifiez s'il y a une erreur dans la requête GraphQL
  if (errorToken || (errorToken && errorToken.networkError && errorToken.networkError.statusCode >= 400 && errorToken.networkError.statusCode < 500)) {
    console.log('disconnect');
    dispatch(logout()); // Déconnectez l'utilisateur
  }

  const handleClickConseil = (id) => {
    navigate(`/conseils/${id}`);
  };

  const handleClickProgramme = (id) => {
    navigate(`/entrainements/programme/${id}`);
  };

  const handleClickSeance = (id) => {
    navigate(`/entrainements/seance/${id}`);
  };

  const favorisProgrammes = [];
  const favorisSeances = [];
  const favorisConseils = [];

  // Fonction pour calculer le pourcentage de progression
  const calculateProgression = (totalSeances, completedSeances) => {
    if (totalSeances === 0) return 0;
    return (completedSeances / totalSeances) * 100;
  };

  const avancementUser = data?.viewer?.userDatas?.avancement;

  data?.viewer?.userDatas?.favoris?.nodes.forEach((object) => {
    if (object.contentTypeName === 'programmes') {
      const nbSeances = object?.seances?.planificationProgramme?.reduce(
        (acc, planification) =>
          acc +
          (planification?.synchProgrammesSeances?.nodes?.filter(
            (seance) => seance.status === "publish"
          ).length || 0),
        0
      );
      const filteredData = avancementUser?.filter(item => item.programmeId === object?.databaseId.toString());
      const completedSeances = filteredData?.reduce((total, item) => total + item.seances?.length, 0);
      const avancementPerCent = Math.round(calculateProgression(nbSeances, completedSeances));
      favorisProgrammes.push(
        <ProgrammesCompo
          onClick={() => handleClickProgramme(object?.id)}
          key={object?.id}
          niveau={object?.niveaux?.edges[0]?.node?.name}
          niveauSlug={object?.niveaux?.edges[0]?.node?.slug}
          label={object?.title}
          nbSeance={nbSeances} // N'EST PAS DANS L'OBJECT RENVOYÉ PAR L'API
          src={object?.thumbnailsProgramme?.thumbnailProgrammePaysage?.node?.sourceUrl}
          srcSet={object?.thumbnailsProgramme?.thumbnailProgrammePaysage?.node?.srcSet}
          alt={object?.thumbnailsProgramme?.thumbnailProgrammePaysage?.node?.altText}
          id={object?.id}
          avancement={avancementPerCent}
        />
      );
    } else if (object.contentTypeName === 'seances') {
      favorisSeances.push(
        <SeancesCompo
          onClick={() => handleClickSeance(object?.id)}
          key={object?.id}
          duree={object?.datasSeances?.dureeSeance}
          niveau={object?.niveaux?.edges[0]?.node?.name}
          niveauSlug={object?.niveaux?.edges[0]?.node?.slug}
          intensite={object?.intensites?.edges[0]?.node?.name}
          intensiteSlug={object?.intensites?.edges[0]?.node?.slug}
          label={object?.title}
          src={object?.thumbnailSeance?.thumbnailSeancePaysage?.node?.sourceUrl}
          srcSet={object?.thumbnailSeance?.thumbnailSeancePaysage?.node?.srcSet}
          alt={object?.thumbnailSeance?.thumbnailSeancePaysage?.node?.altText}
          id={object?.id}
        />
      );
    } else if (object.contentTypeName === 'conseils') {
      const conseilPublished = object?.configConseils.publierApp;
      if(conseilPublished)
        favorisConseils.push(
          <ConseilsCompo
            key={object.id}
            label={object?.title}
            alt={object?.thumbnailsConseil?.thumbnailConseilPaysage?.node?.altText}
            src={object?.thumbnailsConseil?.thumbnailConseilPaysage?.node?.sourceUrl}
            srcSet={object?.thumbnailsConseil?.thumbnailConseilPaysage?.node?.srcSet}
            id={object?.id}
            onClick={() => handleClickConseil(object.id)}
          />
        );
    }
  });

  return (
    <div className='w-full'>
      <div className='topBloc background sticked content'>
        <div className='leftSide'>
          <div className='return lg:hidden'>
            <FontAwesomeIcon
              icon='fa-solid fa-chevron-left'
              onClick={() => {
                navigate('/profil');
              }}
            />
          </div>
          <h1>Favoris</h1>
        </div>

        <div className='rightSide'>
          <div
            className='IconProfil lg:hidden'
            onClick={() => {
              navigate('/profil');
            }}
          ><FontAwesomeIcon icon='fa-solid fa-user' /></div>
        </div>
      </div>

      <div className="blurredPattern perle"></div>

      {loading ? (

        <div className='contentFavoris content loading'>
          <div className='loading'>
            <FontAwesomeIcon className='animate-spin' icon='fa-duotone fa-spinner-third' />
          </div>
        </div>

      ) : (

        <div className='contentFavoris content'>
          
          { favorisProgrammes.length > 0 && (
            <>
              { favorisProgrammes.length > 1 ? (
                <h2 className='titleBlock'>Programmes</h2>
              ) : (
                <h2 className='titleBlock'>Programme</h2>
              )}
              <div className='contentBlock'>
                {favorisProgrammes}
              </div>
            </>
          )}

          { favorisSeances.length > 0 && (
            <>
              { favorisSeances.length > 1 ? (
                <h2 className='titleBlock'>Séances</h2>
              ) : (
                <h2 className='titleBlock'>Séance</h2>
              )}
              <div className='contentBlock'>
                {favorisSeances}
              </div>
            </>
          )}

          { favorisConseils.length > 0 && (
            <>
              { favorisConseils.length > 1 ? (
                <h2 className='titleBlock'>Conseils</h2>
              ) : (
                <h2 className='titleBlock'>Conseil</h2>
              )}
              <div className='contentBlock'>
                {favorisConseils}
              </div>
            </>
          )}
          
        </div>

      )}

    </div>
  );
};

export default Favoris;
