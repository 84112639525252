import React from 'react';
import { Provider } from 'react-redux';
import { store, persistor } from './store/store.js';
import { PersistGate } from 'redux-persist/integration/react';
import Router from './Router.js';
import { ApolloProvider } from '@apollo/client';
import {client} from './api.js';
import { tokenExport } from './store/userSlice.js'
//import { SpeedInsights } from '@vercel/speed-insights/react';
//import { Analytics } from "@vercel/analytics/react";

function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ApolloProvider client={client}>
            <Router />
          </ApolloProvider>
        </PersistGate>
      </Provider>
      {/* <Analytics /> */}
      {/* <SpeedInsights /> */}
    </>
  );
}

export default App;
